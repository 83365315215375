.satisfaction-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.satisfaction-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.category-label {
    width: 400px;
    /* Increased width to give more room for text */
    text-align: right;
    margin-right: 10px;
}

.slider {
    flex-grow: 1;
    margin-right: 10px;
}

.satisfaction-value {
    width: 30px;
    /* Adjust width as needed */
    text-align: left;
}